import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const DashboardGrafik = () => import('@/views/DashboardGrafik')

const User = () => import('@/views/user/listuser')
const TambahUser = () => import('@/views/user/tambahuser')
const EditUser = () => import('@/views/user/edituser')

const News = () => import('@/views/news/listnews')
const TambahNews = () => import('@/views/news/tambahnews')
const EditNews = () => import('@/views/news/editnews')

const Rubrik = () => import('@/views/rubrik/listrubrik')
const TambahRubrik = () => import('@/views/rubrik/tambahrubrik')
const EditRubrik = () => import('@/views/rubrik/editrubrik')

const Iklan = () => import('@/views/iklan/listiklan')
const TambahIklan = () => import('@/views/iklan/tambahiklan')
const EditIklan = () => import('@/views/iklan/editiklan')

const InsideTalk = () => import('@/views/insidetalk/listinsidetalk')
const TambahInsideTalk = () => import('@/views/insidetalk/tambahinsidetalk')
const EditInsideTalk = () => import('@/views/insidetalk/editinsidetalk')

const DalamProvinsi = () => import('@/views/dalamprovinsi/listdalamprovinsi')
const TambahDalamProvinsi = () => import('@/views/dalamprovinsi/tambahdalamprovinsi')
const EditDalamProvinsi = () => import('@/views/dalamprovinsi/editdalamprovinsi')

const LuarProvinsi = () => import('@/views/luarprovinsi/listluarprovinsi')
const TambahLuarProvinsi = () => import('@/views/luarprovinsi/tambahluarprovinsi')
const EditLuarProvinsi = () => import('@/views/luarprovinsi/editluarprovinsi')

const LuarNegeri = () => import('@/views/luarnegeri/listluarnegeri')
const TambahLuarNegeri = () => import('@/views/luarnegeri/tambahluarnegeri')
const EditLuarNegeri = () => import('@/views/luarnegeri/editluarnegeri')

const Peserta = () => import('@/views/peserta/listpeserta')
const TambahPeserta = () => import('@/views/peserta/tambahpeserta')
const EditPeserta = () => import('@/views/peserta/editpeserta')

const Anggaran = () => import('@/views/anggaran/listanggaran')
const TambahAnggaran = () => import('@/views/anggaran/tambahanggaran')
const EditAnggaran = () => import('@/views/anggaran/editanggaran')

const UPT = () => import('@/views/upt/listupt')
const TambahUPT = () => import('@/views/upt/tambahupt')
const EditUPT = () => import('@/views/upt/editupt')

const SuratTugas = () => import('@/views/surattugas/listsurattugas')
const TambahSuratTugas = () => import('@/views/surattugas/tambahsurattugas')
const EditSuratTugas = () => import('@/views/surattugas/editsurattugas')
const DetailSuratTugas = () => import('@/views/surattugas/detailsurattugas')
const UploadSuratTugas = () => import('@/views/surattugas/upload')
const ReportSuratTugas = () => import('@/views/surattugas/report')

const Profilku = () => import('@/views/profilku/profilku')
const LaporanDalamProvinsi = () => import('@/views/laporan/laporandalamprovinsi')
const DetailLaporanDalamProvinsi = () => import('@/views/laporan/uploadlaporan')

const LaporanLuarProvinsi = () => import('@/views/laporan/laporanluarprovinsi')
const DetailLaporanLuarProvinsi = () => import('@/views/laporan/uploadlaporan')

const LaporanLuarNegeri = () => import('@/views/laporan/laporanluarnegeri')
const DetailLaporanLuarNegeri = () => import('@/views/laporan/uploadlaporan')

const Pos = () => import('@/views/Pos')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)
let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'pos',
          name: 'Pos',
          component: Pos,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'perjadin/laporanluarprovinsi',
          name: 'Laporan Luar Provinsi',
          component: LaporanLuarProvinsi,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'perjadin/laporanluarnegeri',
          name: 'Laporan Luar Negeri',
          component: LaporanLuarNegeri,
          meta: {
            requiresAuth: true
          }
        }, 
                
        {
          path: 'perjadin/laporandalamprovinsi',
          redirect: 'perjadin/laporandalamprovinsi/list',
          name: 'Laporan Dalam Provinsi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Laporan Dalam Provinsi',
              component: LaporanDalamProvinsi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'detail/:id_perjadin',
              name: 'Detail Laporan Dalam Provinsi',
              component: DetailLaporanDalamProvinsi,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },        
        {
          path: 'perjadin/dalamprovinsi',
          redirect: 'perjadin/dalamprovinsi/list',
          name: 'Dalam Provinsi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Dalam Provinsi',
              component: DalamProvinsi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Dalam Provinsi',
              component: TambahDalamProvinsi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_perjadin',
              name: 'Edit Dalam Provinsi',
              component: EditDalamProvinsi,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'perjadin/luarnegeri',
          redirect: 'perjadin/luarnegeri/list',
          name: 'Luar Negeri',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Luar Negeri',
              component: LuarNegeri,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Luar Negeri',
              component: TambahLuarNegeri,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_perjadin',
              name: 'Edit Luar Negeri',
              component: EditLuarNegeri,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'perjadin/luarprovinsi',
          redirect: 'perjadin/luarprovinsi/list',
          name: 'Luar Provinsi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Luar Provinsi',
              component: LuarProvinsi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Luar Provinsi',
              component: TambahLuarProvinsi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_perjadin',
              name: 'Edit Luar Provinsi',
              component: EditLuarProvinsi,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'surattugas',
          redirect: '/surattugas/list',
          name: 'Surat Tugas',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Surat Tugas',
              component: SuratTugas,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Surat Tugas',
              component: TambahSuratTugas,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_surat_tugas',
              name: 'Edit Surat Tugas',
              component: EditSuratTugas,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'detail/:id_surat_tugas',
              name: 'Detail Surat Tugas',
              component: DetailSuratTugas,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'upload/:id_surat_tugas',
              name: 'Upload Surat Tugas',
              component: UploadSuratTugas,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'peserta',
          redirect: '/peserta/list',
          name: 'Peserta',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Peserta',
              component: Peserta,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Peserta',
              component: TambahPeserta,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_pegawai',
              name: 'Edit Peserta',
              component: EditPeserta,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'anggaran',
          redirect: '/anggaran/list',
          name: 'Anggaran',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Anggaran',
              component: Anggaran,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Anggaran',
              component: TambahAnggaran,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_anggaran',
              name: 'Edit Anggaran',
              component: EditAnggaran,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'upt',
          redirect: '/upt/list',
          name: 'UPT',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List UPT',
              component: UPT,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add UPT',
              component: TambahUPT,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_upt',
              name: 'Edit UPT',
              component: EditUPT,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'news',
          redirect: '/news/list',
          name: 'News',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List News',
              component: News,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add News',
              component: TambahNews,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_news',
              name: 'Edit News',
              component: EditNews,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'user',
          redirect: '/user/list',
          name: 'User',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List User',
              component: User,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add User',
              component: TambahUser,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_user',
              name: 'Edit User',
              component: EditUser,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'rubrik',
          redirect: '/rubrik/list',
          name: 'Rubrik',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Rubrik',
              component: Rubrik,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Rubrik',
              component: TambahRubrik,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_rubrik',
              name: 'Edit Rubrik',
              component: EditRubrik,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'insidetalk',
          redirect: '/insidetalk/list',
          name: 'InsideTalk',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List InsideTalk',
              component: InsideTalk,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add InsideTalk',
              component: TambahInsideTalk,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_insidetalk',
              name: 'Edit InsideTalk',
              component: EditInsideTalk,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'iklan',
          redirect: '/iklan/list',
          name: 'Iklan',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Iklan',
              component: Iklan,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Iklan',
              component: TambahIklan,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_iklan',
              name: 'Edit Iklan',
              component: EditIklan,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        guest: true
      }
    },
    // {
    //   path: '/pages',
    //   redirect: '/pages/404',
    //   name: 'Pages',
    //   component: {
    //     render(c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: '500',
    //       name: 'Page500',
    //       component: Page500
    //     },
    //   ]
    // }
  ]
}

// Meta Handling


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') === '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       let user = JSON.parse(localStorage.getItem('user'))
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       if (to.matched.some(record => record.meta.is_admin)) {
//         next({ path: '/dashboard' })
//       } else {
//         next()
//       }
//     }
//   } else if (to.matched.some(record => record.meta.guest)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       next({ path: '/dashboard' })

//     }
//   } else {
//     next()
//   }
// })

const redirectToLogin = route => {
  const LOGIN = 'Login';
  if (route.name != LOGIN) {
    return { name: LOGIN, replace: true, query: { redirectFrom: route.fullPath } };
  }
};

const redirectToHome = route => {
  const DEFAULT = 'Dashboard';
  return { name: DEFAULT, replace: true };
};

const DEFAULT_TITLE = 'CMS Portal Inside Pontianak';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == "") {
  //     router.push({ path: '/login' });
  //   } else {
  //     let user = JSON.parse(localStorage.getItem('user'))
  //     if (user == null) {
  //       if (user.level == 1) {
  //         router.push({ path: '/dashoard' });
  //       } else {
  //         // next({ path: '/dashboard' })
  //         router.push({ path: '/dashoard' });
  //       }
  //     } else {
  //       router.push({ path: '/login' });
  //     }
  //   }
  // } else if (to.matched.some(record => record.meta.guest)) {
  //   if (localStorage.getItem('jwt') == null) {
  //     router.push({ path: '/login' });
  //   } else {
  //     next({ path: '/dashboard' })
  //   }
  // } else {
  //   router.push({ path: '/login' });
  // }

  const userIsAuthenticated = localStorage.getItem('jwt');
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  // if (localStorage.getItem('jwt') == null) {
  //   router.push({ path: '/login' });
  // } else {
  //   next({ path: '/dashboard' })
  // }
  // if (!userIsAuthenticated && to.fullPath === '/') {
  //   alert('ERROR1');
  //   return redirectToLogin(to);
  // }
  // alert(to.path);
  if (to.path == '/register') {
    next();
  }
  else if (to.path !== '/login') {
    if (requiresAuth) {
      if (!userIsAuthenticated) {
        alert('Maaf! Anda harus login terlebih dahulu');
        router.push({ path: '/login' });
      }
      else {
        // alert('ERROR1');
        next();
      }
    }
    else {
      // alert('ERROR3');
      next({ path: '/login' })
    }


    return true;
  }
  else {
    next()
  }
});

export default router;

